<template>
  <section class="all-cases-view" id="all-hospitals" v-loading="loading">
    <div class="navbarComponent">
      <div class="container-sm-table">
        <div class="listings-navbar">
          <el-row>
            <el-col :lg="12">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li v-if="hasPermission('hospitals', 'add')">
                  <el-button class="btn button-with-icon" @click="addPhysician">
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/profile-icon-bg-blue.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/profile-icon-bg-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>PHYSICIAN</span>
                  </el-button>
                </li>
                <li v-if="hasPermission('hospitals', 'add')">
                  <el-button class="btn button-with-icon" @click="addHospital">
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/plus-icon.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/plus-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>ADD NEW ORDERING FACILITY</span>
                  </el-button>
                </li>

                <li>
                  <el-button
                    class="btn button-with-icon"
                    @click="downloadExport"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/export-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>Export</span>
                  </el-button>
                </li>
              </ul>
            </el-col>
            <el-col :lg="12">
              <ul
                class="d-flex flex-wrap justify-content-end list-items-group-15"
              >
                <li>
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="searchBy"
                      clearable
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      placeholder="Select Search"
                      @change="clearInputSearch"
                    >
                      <el-option
                        v-for="item in searchOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>

                <li v-if="searchBy">
                  <div class="form-element input-with-icon search-input-lg">
                    <el-input
                      placeholder="Search"
                      v-model="search_string"
                      @input="getAutoCompleteCases()"
                      :clearable="true"
                    ></el-input>
                    <span>
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid icon"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="container-sm-table" style="padding-top: 0">
      <div class="primary-content-view-new">
        <div class="listings listings__table listings__all-cases">
          <el-table
            :data="hospitalsData.data"
            height="calc(100vh - 200px)"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
          >
            <el-table-column
              sortable="custom"
              prop="code"
              label="CODE"
              width="150"
            >
              <template #default="scope">
                <span class="emr-id cursor" @click="onView(scope.row)">
                  {{ scope.row.code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              prop="name"
              label="ORDERING FACILITIES"
              min-width="250"
            >
              <template #default="scope">
                <span class="table_cell-ellipsis">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              prop="phone"
              label="PHONE NUMBER"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              sortable="custom"
              prop="fax"
              label="FAX"
              min-width="150"
            >
            </el-table-column>
            <el-table-column label="Status" min-width="150">
              <template #default="scope">
                <div class="column-status cursor">
                  <el-dropdown
                    class="status-dropdown"
                    @command="(command) => updateStatus(scope.row, command)"
                  >
                    <span class="el-dropdown-link">
                      <el-tag :type="getStatusType(scope.row.status)">{{
                        scope.row.status.split("_").join(" ")
                      }}</el-tag>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu class="status-dropdown-menu">
                        <el-dropdown-item
                          command="ACTIVE"
                          :disabled="scope.row.status === 'ACTIVE'"
                          >Active</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="INACTIVE"
                          :disabled="scope.row.status === 'INACTIVE'"
                          >Inactive</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Action" width="100" fixed="right">
              <template v-slot="scope">
                <ul class="listings__action-buttons">
                  <li>
                    <el-button
                      @click="onView(scope.row)"
                      title="View Ordering Facility"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout="prev, pager, next, jumper, sizes, total"
            :total="hospitalsData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div>
      <HospitalExports
        v-if="exportDialogVisisble"
        v-on:close="exportDialogVisisble = false"
        :selected_search_phone="
          searchBy && searchBy == 'phone' ? search_string : ''
        "
        :selected_search_fax="
          searchBy && searchBy == 'fax' ? search_string : ''
        "
        :selected_search_code="
          searchBy && searchBy == 'code' ? search_string : ''
        "
        :selected_search_name="
          searchBy && searchBy == 'name' ? search_string : ''
        "
        :selected_search_status="selectedStatuses"
      >
      </HospitalExports>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import FilterHelper from "@/mixins/FilterHelper";
import { hospitalsSearchOptions } from "@/config/constants/searchConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
const HospitalExports = defineAsyncComponent(() =>
  import("@/components/hospitals/ExportHospitals")
);
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  name: "hospital",
  data() {
    return {
      scrollPosition: null,
      searchBy: "",
      search_string: "",
      searchOptions: hospitalsSearchOptions,
      exportDialogVisisble: false,
      page: 1,
      pageSize: 25,
      loading: false,
      orderBy: "created_at",
      orderType: "DESC",
      search_fax: "",
      search_phone: "",
      fieldList: [],
      hospitalsData: {},
    };
  },
  mixins: [FilterHelper, UserPermissionsHelper],
  props: {
    selectedStatuses: {
      default: "",
    },
  },
  components: {
    HospitalExports,
  },
  computed: {
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "success";
        } else if (status == "INACTIVE") {
          return "danger";
        } else {
          return "default";
        }
      };
    },
    ...mapGetters("hospitals", ["getAllHospitals", "getHospitalStatusUpdate"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  async mounted() {
    if (this.$route.query) {
      this.setQueryParamsData();
    }
    await this.fetchHospitalsList();
  },
  methods: {
    clearInputSearch() {
      if (this.search_string) {
        this.search_string = "";
        this.page = 1;
        this.fetchHospitalsList(1);
      }
    },
    getAutoCompleteCases() {
      setTimeout(() => {
        this.page = 1;
        this.fetchHospitalsList();
      }, 600);
    },
    async updateStatus(hospital, status) {
      try {
        this.loading = true;
        let params = {
          hospitalId: hospital._id,
          data: {
            status: status,
          },
        };
        await this.$store.dispatch("hospitals/updateHospitalStatus", params);
        if (this.getHospitalStatusUpdate) {
          successNotification("Status updated successfully");
          await this.fetchHospitalsList(this.page);
        } else {
          errorNotification(this.getError || "Error in update status");
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    downloadExport() {
      this.exportDialogVisisble = true;
    },
    onView(hospitalInfo) {
      this.$router.push({
        name: "Hospital-Primary-Information",
        params: { hospital_id: hospitalInfo._id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    addPhysician() {
      this.$router.push({
        name: "AddNewPhysician",
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    setQueryParamsData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedOrderingFacilityPage
          ? parseInt(this.$route.query.selectedOrderingFacilityPage)
          : 1;
        this.pageSize = this.$route.query.selectedOrderingFacilityPageSize
          ? parseInt(this.$route.query.selectedOrderingFacilityPageSize)
          : 25;
        if (this.$route.query.selectedOrderingFacilitySearchBy) {
          this.searchBy = this.$route.query.selectedOrderingFacilitySearchBy;
        }
        if (this.$route.query.selectedOrderingFacilityFax) {
          this.search_string = this.$route.query.selectedOrderingFacilityFax;
        }
        if (this.$route.query.selectedOrderingFacilityPhone) {
          this.search_string = this.$route.query.selectedOrderingFacilityPhone;
        }
        if (this.$route.query.selectedOrderingFacilityName) {
          this.search_string = this.$route.query.selectedOrderingFacilityName;
        }
        if (this.$route.query.selectedOrderingFacilityCode) {
          this.search_string = this.$route.query.selectedOrderingFacilityCode;
        }
        if (this.$route.query.selectedOrderingFacilityOrderBy) {
          this.orderBy = this.$route.query.selectedOrderingFacilityOrderBy;
        }
        if (this.$route.query.selectedOrderingFacilityOrderType) {
          this.orderType = this.$route.query.selectedOrderingFacilityOrderType;
        }
        if (this.$route.query.selectedOrderingFacility) {
          this.hospital = this.$route.query.selectedOrderingFacility;
        }
      }
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.selectedStatuses) {
        params.status = this.selectedStatuses;
      }
      if (this.searchBy && this.search_string) {
        params[this.searchBy] = this.search_string;
      }
      return params;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedOrderingFacilityPage: params.page,
        selectedOrderingFacility: params.hospital,
        selectedOrderingFacilityPageSize: params.limit,
        selectedOrderingFacilityOrderBy: params.order_by,
        selectedOrderingFacilityOrderType: params.order_type,
        selectedOrderingFacilityName: undefined,
        selectedOrderingFacilityFax: undefined,
        selectedOrderingFacilityCode: undefined,
        selectedOrderingFacilityPhone: undefined,

        selectedOrderingFacilitySearchBy: this.searchBy || undefined,
      };
      if (params.name) {
        selectedParams.selectedOrderingFacilityName = params.name;
      }
      if (params.fax) {
        selectedParams.selectedOrderingFacilityFax = params.fax;
      }
      if (params.code) {
        selectedParams.selectedOrderingFacilityCode = params.code;
      }
      if (params.phone) {
        selectedParams.selectedOrderingFacilityPhone = params.phone;
      }
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchHospitalsList();
    },
    addHospital() {
      this.$router.push({
        name: "NewHospital",
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchHospitalsList();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchHospitalsList();
    },
    async fetchHospitalsList() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("hospitals/fetchAllHospitals", params);
        this.hospitalsData = JSON.parse(JSON.stringify(this.getAllHospitals));
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 10px;
  height: 10px;
}
</style>

<style lang="scss">
#all-hospitals {
  .el-dialog {
    .el-dialog__body {
      padding: 0;
    }
  }
  .container-sm-table {
    padding: 10px 0;
  }
  .primary-content-view-new {
    margin-top: 0;
  }
  .stickyNavbar {
    padding-bottom: 5px !important;
    .container-sm-table {
      padding-top: 0;
      padding-bottom: 5px;
    }
  }
}
</style>
